.spinner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    mat-spinner {
        margin: 1rem 0;
    }
}
