.iframe {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    iframe {
        width: 100%;
        -ms-flex-preferred-size: 1;
        flex-basis: 1;
    }
}

button.large {
    padding: 0.4rem 1.2rem;
    font-size: 1.1rem;
    border-radius: unset;

    &.dark-blue {
        background: #377eba;
        color: white;
    }
}

.success {
    color: #32cd32;
}

.danger {
    color: var(--warn-color);
}

.primary {
    color: var(--primary-color);
}

$success-color: #32cd32;

.mat-success {
    background-color: $success-color;
    color: #fff;
}

.mat-danger {
    background-color: var(--warn-color);
    color: #fff;
}

mat-icon.mat-danger {
    background-color: unset !important;
    color: var(--warn-color);
}

mat-icon.mat-success {
    background-color: unset !important;
    color: $success-color;
}

.mat-neutral {
    background-color: darkgrey;
    color: #fff;
}

.mat-secondary {
    background-color: #007ec2;
    color: #fff;
}

.mat-react {
    background-color: #e91e63;
    color: #fff;
}

// TODO: support other badge sizes
.mat-stretch-badge.mat-badge-large>.mat-badge-content {
    width: auto;
    min-width: 28px;
    border-radius: 14px;
    font-size: 20px;
    padding: 0 2px;
    right: unset !important;
}

.h1 {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

.h2 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0.3rem;
}

.h3 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 0.2rem;
}

.h4 {
    font-size: 1.2rem;
    font-weight: bolder;
    margin-top: 0;
    margin-bottom: 0.1rem;
}

.h5 {
    font-size: 1rem;
    font-weight: bolder;
}

.bg-default {
    background-color: var(--default-background)
}

.muted {
    color: var(--muted-color)
}