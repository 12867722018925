@import "tailwind";
@import "theme";
@import "custom";
@import "spinner";
@import "calendly";
@import "buttons/buttons";
// @import "ng-select";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "typo";
@import "material/acorrdian";

html,
body {
    --success-darker-color: #007700;
    height: 100%;
}

body {
    margin: 0;
    font-family: "Montserrat", sans-serif;
}

body {
    --success-darker-color: #007700;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    color: white;
}

.mat-button-focus-overlay {
    background-color: transparent !important;
}

// mat-dialog-container {
//     overflow: hidden !important;
// }

// scroll bar style
::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
}

cdk-stepper::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-track {
    background: rgba(113, 215, 255, 0.435);
}

::-webkit-scrollbar-thumb {
    background: #00b3fa; //handle
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #5595c0d3; //handle hover
}

//end of scrollbar style

// Canlendly Badge Widget
// .calendly-badge-widget {
//     right: 30rem;
//     .calendly-badge-content {
//         background: white;
//         color: #00b2f9;
//     }
// }

.snackbar {
    &-error {
        background-color: var(--warn-darker-color);
        color: white;
    }

    &-success {
        background-color: var(--success-darker-color);
        color: white;
    }
}

.templates-tab-label-custom {
    border: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0;
    .content{
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: center
    }
    mat-icon {
        margin-right: 0.75rem;
    }
    &.mat-tab-label-active {
        background-color: #008bdf;
        color: white;
        opacity: 1 !important;
        box-shadow:
            4px 7px 6px 0px rgba(0, 0, 0, 0.4),
            -2px 7px 4px 0px rgba(0, 0, 0, 0.4);
    }
    &:not(.mat-tab-label-active):last-child {
        box-shadow: 2px 7px 4px 0px rgba(0, 0, 0, 0.2);
    }
}
