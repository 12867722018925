.calendly-inline-widget,
.calendly-inline-widget *,
.calendly-badge-widget,
.calendly-badge-widget *,
.calendly-overlay,
.calendly-overlay * {
    font-size: 16px;
    line-height: 1.2em;
}
.calendly-inline-widget iframe,
.calendly-badge-widget iframe,
.calendly-overlay iframe {
    display: inline;
    width: 100%;
    height: 100%;
}
.calendly-popup-content {
    position: relative;
}
.calendly-popup-content.calendly-mobile {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
}
.calendly-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 9999;
    background-color: #a5a5a5;
    background-color: rgba(31, 31, 31, 0.4);
}
.calendly-overlay .calendly-close-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.calendly-overlay .calendly-popup {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 80%;
    min-width: 900px;
    max-width: 1000px;
    height: 90%;
    max-height: 680px;
}
@media (max-width: 975px) {
    .calendly-overlay .calendly-popup {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transform: none;
        transform: none;
        width: 100%;
        height: auto;
        min-width: 0;
        max-height: none;
    }
}
.calendly-overlay .calendly-popup .calendly-popup-content {
    height: 100%;
}
.calendly-overlay .calendly-popup-close {
    position: absolute;
    top: 25px;
    right: 25px;
    color: #fff;
    width: 19px;
    height: 19px;
    cursor: pointer;
    background: url(/assets/external/close-icon.svg) no-repeat;
    background-size: contain;
}
@media (max-width: 975px) {
    .calendly-overlay .calendly-popup-close {
        top: 15px;
        right: 15px;
    }
}
.calendly-badge-widget {
    position: fixed;
    right: 40rem;
    z-index: 1000;
    @media only screen and (min-width: 1700px) {
        top: 1.5rem;
    }
    @media only screen and (min-width: 950px) and (max-width: 1700px), (min-width: 600px) and (max-width: 950px) {
        right: 105px;
        bottom: 15px;
    }
}
.calendly-badge-widget .calendly-badge-content {
    display: table-cell;
    width: auto;
    height: 45px;
    padding: 0 30px;
    border-radius: 25px;
    box-shadow: rgba(0, 0, 0, 0.25) 0 2px 5px;
    font-family: sans-serif;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}
.calendly-badge-widget .calendly-badge-content.calendly-white {
    color: #666a73;
}
.calendly-badge-widget .calendly-badge-content span {
    display: block;
    font-size: 12px;
}
.calendly-spinner {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    z-index: -1;
}
.calendly-spinner > div {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #e1e1e1;
    border-radius: 50%;
    vertical-align: middle;
    -webkit-animation: calendly-bouncedelay 1.4s infinite ease-in-out;
    animation: calendly-bouncedelay 1.4s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.calendly-spinner .calendly-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.calendly-spinner .calendly-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes calendly-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes calendly-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
