ng-oct-report {
    .info {
        color: #0077b8 !important;
    }
    .warning {
        color: #ffcc00 !important;
    }
    .danger {
        color: #ff6600 !important;
    }
    .critical,
    .red {
        color: red;
    }
    .safe {
        color: limegreen;
    }
}
